import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Carousel, Accordion } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import Label from '../../Components/Label/Label';
import FindChallenge from '../../Assets/Images/find-challenge.png';
import FindTeam from '../../Assets/Images/find-team.png';
import OnlineMeet from '../../Assets/Images/online-meet.png';
import OnProgress from '../../Assets/Images/on-progress.png';
import Button from '../../Components/Button/Button';
import CheckedIcon from '../../Assets/Images/checked-icon.png';
import Testimonial1 from '../../Assets/Images/testimonial_1.png';
import Testimonial3 from '../../Assets/Images/testimonial_nur_laela.png';
import Testimonial4 from '../../Assets/Images/testimonial_yusril.png';
import Testimonial5 from '../../Assets/Images/testimonial_agustina_betsy.png';
import Testimonial6 from '../../Assets/Images/testimonial_diva_desmieta.png';
import Testimonial7 from '../../Assets/Images/testimonial_kuncoro.png';
import Testimonial8 from '../../Assets/Images/testimonial-karimatul.png';
import Testimonial9 from '../../Assets/Images/testimonial-luciana.png';
// import Testimonial2 from '../../Assets/Images/testimonial_2.png';
import Blockquote from '../../Assets/Images/blockquote.png';
import './HowItWorks.scss';
import SignInModal from '../../Components/SignInModal/SignInModal';
import ModalGuidelines from '../../Components/ModalGuidelines/ModalGuidelines';
import {
  ANIMATION_GUIDELINE_URL,
  ASSET_GUIDELINE_URL,
  DESIGN_GUIDELINE_URL,
  TEACHER_GUIDELINE_URL,
  LESSON_GUIDELINE_URL,
  HOW_ITS_WORK_URL,
  HASH_DECRYPT,
} from '../../Constants/Constants';
import { decryptData } from '../../Helpers/Common';

const HowItWorks = () => {
  const [activeStage, setActiveStage] = useState(1);
  const [showSigInModal, setShowSignInModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [guidelineDetail, setGuidelineDetail] = useState({});
  const history = useHistory();

  const player = useRef(null);
  const [playerDuration, setPlayerDuration] = useState(0);
  const [playerPlaying, setPlayerPlaying] = useState(false);
  const [playerProgress, setPlayerProgress] = useState(0);

  // Ref for video stage!
  const VIDEO_REF = {
    1: 4,
    2: 47,
    3: 87.5,
    4: 168,
    5: 301,
  };

  useEffect(() => {
    const duration = Object.keys(VIDEO_REF).find((key) => VIDEO_REF[key] === Math.floor(playerProgress));
    if (duration !== undefined) {
      setActiveStage(duration);
    }
  }, [playerProgress]);

  const handleDownload = (role) => {
    if (role === 'guru') {
      setShowPreview(true);
      setGuidelineDetail({
        heading: 'Pedoman Guru',
        fileName: 'Panduan Untuk Guru (Bahasa).mp4',
        url: TEACHER_GUIDELINE_URL,
      });
    }
    if (role === 'animasi') {
      setShowPreview(true);
      setGuidelineDetail({
        heading: 'Pedoman Animasi',
        fileName: 'Pedoman Animasi.mp4',
        url: ANIMATION_GUIDELINE_URL,
      });
    }
    if (role === 'desain') {
      window.open(DESIGN_GUIDELINE_URL);
    }
    if (role === 'aset') {
      window.open(ASSET_GUIDELINE_URL);
    }
    if (role === 'lesson') {
      window.open(LESSON_GUIDELINE_URL);
    }
  };

  const handleStart = () => {
    const loggedUser = decryptData(localStorage.getItem('user'), HASH_DECRYPT);
    if (loggedUser) {
      history.push('/papan-pintar');
    } else {
      setShowSignInModal(true);
    }
  };

  const handleVideoSetRef = (value) => {
    setActiveStage(value);
    if (player.current) {
      player.current.seekTo(parseFloat(VIDEO_REF[value]));
      setPlayerPlaying(true);
    }
  };

  const handlePlayerDuration = (duration) => {
    setPlayerDuration(duration);
  };

  const handlePlayerProgress = (progress) => {
    setPlayerProgress(playerDuration * progress.played);
  };

  const handlePlayerEnded = () => {
    setActiveStage(5);
  };

  const renderStages = () => {
    return (
      <div className='how-it-works-stages'>
        <div
          onClick={() => handleVideoSetRef(1)}
          className={`how-it-works-stages-stage ${activeStage >= 1 ? 'active' : ''}`}
        >
          <div className='how-it-works-stages-stage-num'>
            1
            <div className={`how-it-works-stages-stage-desc ${parseInt(activeStage, 10) === 1 ? 'active' : ''}`}>
              Daftar
            </div>
          </div>
          <div className={`how-it-works-stages-stage-tail ${activeStage > 1 ? 'active' : ''}`} />
        </div>
        <div
          onClick={() => handleVideoSetRef(2)}
          className={`how-it-works-stages-stage ${activeStage >= 2 ? 'active' : ''}`}
        >
          <div className='how-it-works-stages-stage-num'>
            2
            <div className={`how-it-works-stages-stage-desc ${parseInt(activeStage, 10) === 2 ? 'active' : ''}`}>
              Cek Tantangan
            </div>
          </div>
          <div className={`how-it-works-stages-stage-tail ${activeStage > 2 ? 'active' : ''}`} />
        </div>
        <div
          onClick={() => handleVideoSetRef(3)}
          className={`how-it-works-stages-stage ${activeStage >= 3 ? 'active' : ''}`}
        >
          <div className='how-it-works-stages-stage-num'>
            3
            <div className={`how-it-works-stages-stage-desc ${parseInt(activeStage, 10) === 3 ? 'active' : ''}`}>
              Temukan Tim
            </div>
          </div>
          <div className={`how-it-works-stages-stage-tail ${activeStage > 3 ? 'active' : ''}`} />
        </div>
        <div
          onClick={() => handleVideoSetRef(4)}
          className={`how-it-works-stages-stage ${activeStage >= 4 ? 'active' : ''}`}
        >
          <div className='how-it-works-stages-stage-num'>
            4
            <div className={`how-it-works-stages-stage-desc ${parseInt(activeStage, 10) === 4 ? 'active' : ''}`}>
              Kolaborasi
            </div>
          </div>
          <div className={`how-it-works-stages-stage-tail ${activeStage > 4 ? 'active' : ''}`} />
        </div>
        <div
          onClick={() => handleVideoSetRef(5)}
          className={`how-it-works-stages-stage ${activeStage >= 5 ? 'active' : ''}`}
          style={{ width: 'auto' }}
        >
          <div className={`how-it-works-stages-stage-num last ${activeStage >= 5 ? 'active' : ''}`}>
            5
            <div className={`how-it-works-stages-stage-desc ${parseInt(activeStage, 10) === 5 ? 'active' : ''}`}>
              Selesai
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className='how-it-works-header'>
        <Container>
          <Row>
            <Col xs={6}>
              <h1 className='how-it-works-header-heading'>Cara Kerja</h1>
              <p className='how-it-works-header-sub'>
                Titik Pintar mentransformasi soal-soal sekolah menjadi game yang menarik sesuai Kurikulum Indonesia.
              </p>
              <br />
              <p className='how-it-works-header-sub'>
                Bisakah tim mu membuat soal-soal menarik yang menyenangkan untuk anak-anak SD?
              </p>
            </Col>
            <Col xs={6}>
              <div className='video-container'>
                <div className='player-wrapper'>
                  <ReactPlayer
                    ref={player}
                    url={HOW_ITS_WORK_URL}
                    width='100%'
                    height='100%'
                    className='react-player'
                    playing={playerPlaying}
                    onDuration={handlePlayerDuration}
                    onProgress={handlePlayerProgress}
                    onEnded={handlePlayerEnded}
                    controls
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {renderStages()}
      </div>
    );
  };

  const directionButtons = (direction) => {
    return (
      <span aria-hidden='true' className={`${direction === 'Next' ? 'button-next' : 'button-prev'} direction-button`}>
        {direction === 'Next' ? (
          <svg width='14' height='25' viewBox='0 0 14 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M2 2L12.5 12.5L2 23' stroke='white' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        ) : (
          <svg width='14' height='25' viewBox='0 0 14 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M12 2L1.5 12.5L12 23'
              stroke='white'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        )}
      </span>
    );
  };

  const renderTestimonial = () => {
    return (
      <div className='how-it-works-testimonial'>
        <Label type='STATUS_IN_PROGRESS'>TESTIMONIAL</Label>
        <h3 className='how-it-works-step-heading'>
          Kami punya cerita untuk <br /> menginspirasi kamu
        </h3>
        <span>Beberapa kata dari pencipta bahagia kami.</span>

        <div className='how-it-works-testimonial-testimonial-wrapper'>
          <Carousel
            interval={3000}
            indicators={true}
            nextIcon={directionButtons('Next')}
            prevIcon={directionButtons('Previous')}
          >
            <Carousel.Item>
              <div className='testimonial-item'>
                <Row>
                  <Col>
                    <img src={Testimonial8} className='avatar' />
                  </Col>
                  <Col>
                    <div className='testimonial-item-blockquote'>
                      <img className='first' src={Blockquote} />
                      <p>
                        Bergabung dengan Sahabat Pintar membuat saya terus berkarya dan menyebar manfaat dengan ilmu
                        serta keahlian yang saya miliki tanpa terbatas ruang Dan waktu. Long life education 💪🏻
                      </p>
                      <img className='last' src={Blockquote} />

                      <h3>Karimatul Rofikoh, S.Pd.</h3>
                      <span>Guru</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='testimonial-item'>
                <Row>
                  <Col>
                    <img src={Testimonial9} className='avatar' />
                  </Col>
                  <Col>
                    <div className='testimonial-item-blockquote'>
                      <img className='first' src={Blockquote} />
                      <p>
                        Dengan mengikuti aturan yang diterapkan oleh Tim Sahabat Pintar, kemudian berkoordinasi dengan
                        designer dan animator, saya jadi belajar banyak tentang bagaimana membuat sebuah video
                        pembelajaran animasi yang menyenangkan dan menarik untuk anak SD.
                      </p>
                      <img className='last' src={Blockquote} />

                      <h3>Luciana Chendrawati</h3>
                      <span>Guru</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='testimonial-item'>
                <Row>
                  <Col>
                    <img src={Testimonial5} className='avatar' />
                  </Col>
                  <Col>
                    <div className='testimonial-item-blockquote'>
                      <img className='first' src={Blockquote} />
                      <p>
                        Sahabat pintar memiliki konten animasi yg menarik dan video pembelajaran interaktif yang
                        menyenangkan untuk anak-anak. Jangan berhenti berkarya, kembangkan terus kemampuan kita untuk
                        kemajuan pendidikan anak-anak Indonesia yang lebih baik.
                      </p>
                      <img className='last' src={Blockquote} />

                      <h3>Agustina Betsy</h3>
                      <span>Guru</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='testimonial-item'>
                <Row>
                  <Col>
                    <img src={Testimonial3} className='avatar' />
                  </Col>
                  <Col>
                    <div className='testimonial-item-blockquote'>
                      <img className='first' src={Blockquote} />
                      <p>
                        Design platformnya yg unik, dan Alby yg dijadikan ciri khasnya Saya menikmatinya. "Semangat
                        berkarya!"
                      </p>
                      <img className='last' src={Blockquote} />

                      <h3>Nur Laela</h3>
                      <span>Mahasiswi</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='testimonial-item'>
                <Row>
                  <Col>
                    <img src={Testimonial6} className='avatar' />
                  </Col>
                  <Col>
                    <div className='testimonial-item-blockquote'>
                      <img className='first' src={Blockquote} />
                      <p>
                        Nambah wawasan dan ilmu. Sahabat pintar bisa mengasah kreatifitas, menambah pemasukan selain
                        nambah ilmu. Yang penting satu team saling bekerjasama dan saling mendukung satu dengan yang
                        lain.
                      </p>
                      <img className='last' src={Blockquote} />

                      <h3>Diva Desmieta</h3>
                      <span>Mahasiswi</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='testimonial-item'>
                <Row>
                  <Col>
                    <img src={Testimonial1} className='avatar' />
                  </Col>
                  <Col>
                    <div className='testimonial-item-blockquote'>
                      <img className='first' src={Blockquote} />
                      <p>
                        {' '}
                        Sahabat pintar benar-benar terbuka dengan seluruh kalangan guru, animator dan designer yang
                        ingin berkontribusi, baik itu mencari penghasilan tambahan maupun yang ingin meningkatkan skill
                        profesional.{' '}
                      </p>
                      <img className='last' src={Blockquote} />

                      <h3>Khevin</h3>
                      <span>Motion Graphic Designer</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item>
                <div className="testimonial-item">
                  <Row>
                    <Col>
                      <img src={Testimonial2} className="avatar" />
                    </Col>
                    <Col>
                      <div className="testimonial-item-blockquote">
                        <img className="first" src={Blockquote} />
                        <p>
                          Teman-teman yang punya talenta di bidang pengajaran Matematika dan IPA serta di
                          bidang design dan animasi, ayo bergabung di Sahabat Pintar untuk menerapkan ilmu
                          yang sudah dikuasai, karena di Sahabat Pintar di samping belajar mencurahkan ide dan
                          ilmu yang ada, kita juga dikasih reward loh, kan lumayan toh juga freelance.
                        </p>
                        <img className="last" src={Blockquote} />

                        <h3>Sahat Marasi</h3>
                        <span>Guru</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Carousel.Item> */}
            <Carousel.Item>
              <div className='testimonial-item'>
                <Row>
                  <Col>
                    <img src={Testimonial4} className='avatar' />
                  </Col>
                  <Col>
                    <div className='testimonial-item-blockquote'>
                      <img className='first' src={Blockquote} />
                      <p>
                        Sahabat pintar mempertemukan orang dengan spesialisasi masing- masing menjadi sebuah tim untuk
                        mengerjakan konten edukasinya dan membagikannya secara gratis. Sepertinya belum ada platform
                        lain dengan konsep seperti ini.
                      </p>
                      <img className='last' src={Blockquote} />

                      <h3>Yusril</h3>
                      <span>Motion Graphic dan Animasi</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='testimonial-item'>
                <Row>
                  <Col>
                    <img src={Testimonial7} className='avatar' />
                  </Col>
                  <Col>
                    <div className='testimonial-item-blockquote'>
                      <img className='first' src={Blockquote} />
                      <p>
                        Menurut saya Sahabat Pintar sangat keren. Dan sesuatu yang baru di dunia pendidikan indonesia.
                        Dengan animasi anak lebih tertarik mempelajari materi. Dan bagi guru bisa menambah ilmu dan
                        pengalaman dalam menyampaikan materi ke siswa.
                      </p>
                      <img className='last' src={Blockquote} />

                      <h3>Kuncoro Edi Wibowo</h3>
                      <span>Guru</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  };

  const renderFindChallenge = () => {
    return (
      <Row as='div' className='how-it-works-find-challenge'>
        <Col xl={4} className='how-it-works-find-challenge-left'>
          <div>
            <Label type='STATUS_IN_PROGRESS'>CEK TANTANGAN</Label>
            <h3 className='how-it-works-step-heading'>
              Telusuri <span className='how-it-works-blue-text'>‘Permintaan’ </span>
              untuk melihat soal yang perlu dibuat.
            </h3>
            <p className='how-it-works-step-text'>Klik Permintaan Soal untuk memilih soal yang ingin kamu buat!</p>
          </div>
        </Col>
        <Col xl={7}>
          <img src={FindChallenge} alt='' />
        </Col>
      </Row>
    );
  };

  const renderFindTeam = () => {
    return (
      <Row as='div' className='how-it-works-find-team'>
        <Col xs={6} as='div' className='how-it-works-find-team-img-container'>
          <img src={FindTeam} alt='' />
          <div className='how-it-works-find-team-img-container-btn'>
            <Button block>
              {' '}
              <i className=' fa fa-user-plus' /> Kirim Permintaan Bergabung
            </Button>
          </div>
        </Col>
        <Col xs={6} className='how-it-works-find-team-right'>
          <div>
            <Label type='STATUS_IN_PROGRESS'>TEMUKAN TIM</Label>
            <h3 className='how-it-works-step-heading'>
              Gabung dengan tim atau temukan sendiri partnermu untuk berkolaborasi!
            </h3>
            <p className='how-it-works-step-text'>
              Kami yakin sebuah tim bisa menaklukan dunia! Temukan rekan terbaik dengan cek ulasan pada profil yang
              tersedia.
            </p>
          </div>
        </Col>
      </Row>
    );
  };

  const renderWorkTogether = () => {
    return (
      <Row as='div' className='how-it-works-work-together'>
        <Col xs={6}>
          <Label type='STATUS_IN_PROGRESS'>KOLABORASI</Label>
          <h3 className='how-it-works-step-heading'>Kerjasama untuk hasilkan karya yang terbaik.</h3>
          <p className='how-it-works-step-text'>
            Setiap guru bertanggung jawab membuat soal yang menarik dengan membuat konsep ilustrasi dan pertanyaan.
            Unduh dan ikuti template yang tersedia untuk membantu proses pembuatan soal. Kerjasama hingga mendapat hasil
            yang memuaskan!
          </p>
          <Row>
            <Col xs={6}>
              <Button block onClick={() => handleDownload('guru')}>
                Lihat Pedoman Untuk Guru
              </Button>
            </Col>
            <Col xs={6}>
              <Button block onClick={() => handleDownload('lesson')}>
                Unduh Template Pelajaran
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <img src={OnlineMeet} alt='' />
        </Col>
      </Row>
    );
  };

  const renderAccordion = () => {
    return (
      <Accordion as='div' eventkey='0'>
        <Accordion.Toggle as='div' eventKey='0' className='how-it-works-accordion-heading'>
          Pedoman Animasi 2 Menit
          <i className='fa fa-chevron-down how-it-works-accordion-heading-pointer' />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='0' as='div' className='how-it-works-accordion-collapse'>
          <div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Ikuti <span className='how-it-works-gold-text'>Titik Pintar’s visual style</span> sebisa mungkin
              </p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Buat animasi
                <span className='how-it-works-bold'> 1-2 menit </span>
                atau 4 scene animasi video sesuai pedoman soal
              </p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Tulis <span className='how-it-works-bold'> voice-over spoken </span> pada template dan unggah dengan
                format PDF
              </p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>Unggah file mp4 (1080p) & dan file After Effect</p>
            </div>
          </div>
        </Accordion.Collapse>

        <Accordion.Toggle as='div' eventKey='1' className='how-it-works-accordion-heading'>
          Pedoman 15 Pertanyaan Q&A
          <i className='fa fa-chevron-down how-it-works-accordion-heading-pointer' />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='1' as='div' className='how-it-works-accordion-collapse'>
          <div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Ikut <span className='how-it-works-gold-text'>Titik Pintar’s visual style</span> sebisa mungkin
              </p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Gunakan template dari Titik Pintar dan perhatikan
                <span className='how-it-works-bold'> safe space area</span>
              </p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>Buat 15 Pertanyaan Q&A sesuai pelajaran yang dibuat</p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>Kirim file AI untuk kami bantu periksa hasil karyamu</p>
            </div>
          </div>
        </Accordion.Collapse>

        <Accordion.Toggle as='div' eventKey='2' className='how-it-works-accordion-heading'>
          Data Pelajaran Yang Sudah Disediakan
          <i className='fa fa-chevron-down how-it-works-accordion-heading-pointer' />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='2' as='div' className='how-it-works-accordion-collapse'>
          <div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Ikut <span className='how-it-works-gold-text'>gaya visual Titik Pintar</span> semaksimal mungkin
              </p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Hanya isi <span className='how-it-works-gold-text'>Pengarahan Animasi</span> dan{' '}
                <span className='how-it-works-gold-text'>Tanya dan Jawab</span>
              </p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Bagian <span className='how-it-works-gold-text'>Data Pelajaran</span> akan disediakan oleh tim Sahabat
                Pintar
              </p>
            </div>
            <div className='how-it-works-accordion-point'>
              <img src={CheckedIcon} alt='' className='how-it-works-accordion-point-check' />
              <p className='how-it-works-accordion-point-text'>
                Kirim Proposal kamu dan tim kami akan meninjau pekerjaanmu
              </p>
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  const renderRequirements = () => {
    return (
      <Row as='div' className='how-it-works-requirements'>
        <Col xs={6}>
          <img src={OnProgress} alt='' />
        </Col>
        <Col xs={6}>
          <Label type='STATUS_IN_PROGRESS'>SELESAI</Label>
          <h3 className='how-it-works-step-heading'>
            Cek hasil karyamu dan pastikan sudah melengkapi semua persyaratan
          </h3>
          <p className='how-it-works-step-text'>
            Jika sudah puas dengan hasilnya, jangan lupa cek detail seperti ukuran file dan persyaratan penting lainnya.
          </p>
          {renderAccordion()}
          <div className='how-it-works-requirements-btn-container'>
            <div className='how-it-works-requirements-btn'>
              <Button block onClick={() => handleDownload('desain')}>
                Lihat Pedoman Untuk Desainer
              </Button>
            </div>
            <div className='how-it-works-requirements-btn'>
              <Button block onClick={() => handleDownload('animasi')}>
                Lihat Pedoman Untuk Animator
              </Button>
            </div>
            <div className='how-it-works-requirements-btn'>
              <Button block onClick={() => handleDownload('aset')}>
                Unduh Paket Aset
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderFooter = () => {
    return (
      <div className='how-it-works-footer'>
        <h2 className='how-it-works-footer-heading'>
          Daftar dan <span className='how-it-works-blue-text'> Menangkan Rp 2 Juta! </span>
        </h2>
        <p className='how-it-works-footer-text'>
          Hasil karya terbaik akan mendapat hadiah! Ribuan anak-anak akan menikmati hasil karyamu dan bermain dengan
          senang!
        </p>
        <div className='how-it-works-footer-btn'>
          <Button onClick={handleStart} block>
            Mulai Sekarang
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavigationBar active='caraKerja' />
      {renderHeader()}
      <Container className='my-projects-container'>
        {renderTestimonial()}
        {renderFindChallenge()}
        {renderFindTeam()}
        {renderWorkTogether()}
        {renderRequirements()}
        <SignInModal isShowModal={showSigInModal} setIsShowModal={setShowSignInModal} />
        <ModalGuidelines showModal={showPreview} setShowModal={setShowPreview} guidelineDetail={guidelineDetail} />
      </Container>
      {renderFooter()}
    </>
  );
};

export default HowItWorks;
